<template>
  <div> 
    <el-dialog :model-value="true"  :title="title" width="350" align-center
    :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <el-form :model="form">
            <el-form-item label="手机号" >
                <el-input v-model="form.phonenum" autocomplete="off" class="fixed-width" />
            </el-form-item>
            <el-form-item label="密&nbsp;&nbsp;码" >
                <el-input v-model="form.pwd" autocomplete="off" class="fixed-width" type="password" @keydown="loginkey" />
            </el-form-item>
        </el-form> 
        <template #footer>
            <div class="dialog-footer"> 
                <el-button type="primary" @click="login" >登陆</el-button>
            </div> 
        </template>
    </el-dialog>
  </div>
</template>

<script>
import service from '@/util/request'
import urlcfg from '@/config/urlcfg'
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            title:'后台管理系统',
            form:{
                phonenum:'',
                pwd:''
            }
        }
    },
    mounted(){
        this.initsysname(); 
    },
    methods:{
        loginkey(event){
            if (event.key === 'Enter') {
                this.login();
            }
        },
        initsysname(){
            service.get(urlcfg.systeminit,{
                params:{
                    cslx:"sysinit"
                }
            }).then(response => {
                if(response.statCode == 200){
                    for(var tem in response.data){
                        localStorage.setItem(response.data[tem].cskey,response.data[tem].csvalue)
                    }
                    this.title = localStorage.getItem("xtmc")
                    document.title = this.title;
                }else{
                    ElMessage.error(response.data)
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        login(){
            service.post(urlcfg.systemlogin,this.form).then(response => {
                console.log(response) 
                if(response.statCode == 200){
                    localStorage.setItem("user",JSON.stringify(response.data));
                    localStorage.setItem("authtoken",response.data.token);
                    this.$router.push("layout/main")
                }else{
                    ElMessage.error(response.data)
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>

<style >
.fixed-width {
    width: 230px;
}
.el-form-item__label {
  min-width: 60px; /* 设置一个合适的最小宽度 */
  max-width: 100px; /* 设置一个合适的最大宽度 */
}
</style>