<template>
<div class="common-layout"> 
   <el-container>
    <el-header> 
        <el-row :gutter="0" type="flex" align="middle"> 
          <el-col :span="21">
            <el-menu 
            class="el-menu-demo"
            mode="horizontal" 
            :default-active="'/layout/main'"
            :router="true" 
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b">  
            <template  v-for="item in menu" :key="item.rid" >
              <el-menu-item :index="item.menuurl" v-if="item.children.length == 0">{{ item.menuname }}</el-menu-item>
              <el-sub-menu :index="item.menuurl" v-if="item.children.length > 0">
                <template #title>{{ item.menuname }}</template>
                <el-menu-item :index="item1.menuurl" v-for="item1 in item.children" :key="item1.rid" >{{ item1.menuname }}</el-menu-item>
              </el-sub-menu>
            </template>
          </el-menu>  
          </el-col>
          <el-col :span="1" class="username">{{ uname }}</el-col>
          <el-col :span="2">
            <el-button type="primary" @click="loginout">
              <el-icon><SwitchButton /></el-icon>
            </el-button>
          </el-col>
        </el-row>
    </el-header>
    <router-view></router-view>
   </el-container> 
  </div>
</template>

<script>
  import router from '../router'

  export default {
    data(){
      return {
        uname:"1",
        menu:[]
      };
    },
    mounted(){
      //通过缓存拿用户权限数据渲染到也没
        this.initsysname();
        //缓存中用户菜单信息
        this.menu = JSON.parse(localStorage.getItem("user")).menu;
        document.body.style.backgroundColor = '#f0f0f0';
        document.body.style.margin = '0';
        document.body.style.padding = '0'; 
        this.title = localStorage.getItem("xtmc")
        document.title = this.title;
    },
    methods:{
      initsysname(){
        const user = JSON.parse(localStorage.getItem("user"));
        this.uname = user.uname;
      },
      
      loginout(){
        localStorage.removeItem("user");
        localStorage.removeItem("authtoken");
        router.push("login")
      }
    }
  }
</script>

<style scoped>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    color: #2c3e50;
  } 
  
  .b {
    margin: 0px; 
    padding: 0px;
    background-color: '#f0f0f0';
  }
  
  .username{
    color: aliceblue;
  }

  a {
    text-decoration: none; /* 去除下划线 */
    color: inherit; /* 继承父元素的颜色 */
    cursor: pointer; /* 修改鼠标指针样式 */
  }
  .el-header{
    background-color: #545c64;
  }
   
</style>
