<template>
    <div>
      <el-row  type="flex" class="row-gap">
        <el-col :span="1" :offset="1">
            <el-tooltip class="item" effect="dark" content="新增角色" placement="right">
              <el-button type="primary" size="small" @click="add = !add">
                <el-icon ><Plus /></el-icon>
              </el-button>
            </el-tooltip>
        </el-col>
      </el-row>
      <el-row  type="flex">
        <el-col :span="13" :offset="1">
          <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          stripe
          :border="true"
          @row-click="selectchange"
          style="width: 100%">
          <el-table-column
            prop="rolename"
            label="角色名"
            width="120">
          </el-table-column>
          <el-table-column
            prop="rolems"
            label="角色描述"
            width="420">
          </el-table-column>
          <el-table-column 
            label="操作"
            width="160">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="维护角色人员信息" placement="left">
                <el-button type="primary" size="small" @click="editroleuser(scope)">
                    <el-icon><Operation /></el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="维护角色菜单信息" placement="top">
                <el-button type="primary" size="small" @click="editrolemenu(scope)">
                    <el-icon><Operation /></el-icon>
                </el-button> 
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除角色" placement="right">
                <el-button type="primary" size="small" @click="deleteRow(scope.row)">
                    <Delete style="width: 1em; height: 1em;" /> 
                </el-button> 
              </el-tooltip>
              
            </template> 
          </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog
            v-model="add"
            title="新增"
            width="650"
            align-center
          >
          <el-form :inline="true"  class="demo-form-inline">
            <el-form-item label="角色名">
              <el-input v-model="rolename" placeholder="角色名"   />
            </el-form-item>
            <el-form-item label="角色描述">
              <el-input v-model="rolems" placeholder="角色描述"   /> 
            </el-form-item> 
          </el-form>
            <template #footer>
              <div class="dialog-footer">
                <el-button type="primary" @click="add = false">取消</el-button> 
                <el-button type="primary" @click="saverole">保存</el-button> 
              </div>
            </template>
      </el-dialog>
      <el-dialog
        v-model="whry"
        title="绑定角色人员"
        width="600"
        align-center
        :show-close="false"
        :close-on-click-modal="false">
        <el-transfer v-model="uservalue" :data="userdata" />
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="whry = false">取消</el-button>
            <el-button type="primary" @click="saveroleuser">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog
        v-model="whcd"
        title="绑定角色菜单"
        width="600"
        align-center
        :show-close="false"
        :close-on-click-modal="false">
        <el-transfer v-model="menuvalue" :data="menudata" />
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="whcd = false">取消</el-button>
            <el-button type="primary" @click="saverolemenu">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import service from '@/util/request'
  import urlcfg from '@/config/urlcfg'
  import { ElMessage } from 'element-plus'

    export default {
      mounted(){
        this.initTable();
      },
      methods: {
        saverole(){
          this.add = false;
          service.post(urlcfg.systemroleadd,{
                rolename:this.rolename,
                rolems:this.rolems
            }).then(response => { 
              ElMessage({
                message: response.data,
                type: 'success',
              })
              this.initTable();
            }).catch(function (error) {
              console.log(error);
            });
        },
        editroleuser(row) {
          this.whry = true;
          this.selectedRoleId = row.row.rid;
          this.initroleuser(row.row.rid);
        },
        saveroleuser() {
          this.whry = false;
          service.post(urlcfg.systemsaveroleuser,{
              roleid:this.selectedRoleId,
              userid:this.uservalue
            }).then(response => { 
              ElMessage({
                message: response.data,
                type: 'success',
              })
            }).catch(function (error) {
              console.log(error);
            });
        },
        saverolemenu() {
          this.whcd = false;
          service.post(urlcfg.systemsaverolement,{
              roleid:this.selectedRoleId,
              menuid:this.menuvalue
            }).then(response => { 
              ElMessage({
                message: response.data,
                type: 'success',
              })
            }).catch(function (error) {
              console.log(error);
            });
        },
        editrolemenu(row){
          this.whcd = true;
          this.selectedRoleId = row.row.rid;
          this.initrolemenu(row.row.rid);
        },
        deleteRow(row){
          console.log(row);
          service.get(urlcfg.systemdelrole,{
            params:{
              rid:row.rid
            }}
          ).then(res => {
              if(res.statCode == '200'){
                ElMessage({
                  message: res.data,
                  type: 'success',
                })
                window.location.reload();
            }else{
                ElMessage.error(res.data)
            }

          }).catch(function (error) {
            console.log(error);
          });
        },
        selectchange(){
          
        },
        initTable(){
            service.get(urlcfg.systemrolelist,{
            }).then(response => {
            //给表格内容赋值
              this.tableData = response.data;
            }).catch(function (error) {
              console.log(error);
            }); 
        },
        initrolemenu(roleid){
          console.log(roleid);
          service.get(urlcfg.systemrolemenulist,{
              params:{
                roleid:roleid
              }
            }).then(response => {
            //给表格内容赋值
              this.menudata = response.data.value;
              this.menuvalue = response.data.key;
            }).catch(function (error) {
              console.log(error);
            }); 
        },
        initroleuser(roleid){
          console.log(roleid);
          service.get(urlcfg.systemroleuserlist,{
              params:{
                roleid:roleid
              }
            }).then(response => {
            //给表格内容赋值
              this.userdata = response.data.value;
              this.uservalue = response.data.key;
            }).catch(function (error) {
              console.log(error);
            }); 
        }
      },
      data() {
        return {
          add:false,
          whry:false,
          whcd:false,
          rolems:'',
          rolename:'',
          selectedRoleId:'',
          uservalue:[],
          menuvalue:[],
          tableData: [],
          userdata:[],
          menudata:[
            {key: '111',label: '张三1'},
            {key: '222',label: '张三2'},
            {key: '333',label: '张三3'}
          ]
        }
      }
    }
  </script> 

<style>
  .row-gap {
    margin-bottom: 20px; /* 设置的间距值 */
  }
</style>
      