import { createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs'





const app = createApp(App)
    app.use(store).use(router)
    .use(ElementPlus, {
        locale: zhCn,
      })
    .mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

router.beforeEach((to,from,next)=>{ 
  if(to.path == '/login' || to.path == '/register' || to.path == '/cpxq'){
    console.log("fffffffffffffffffffffff")
    next();
  }else{
    const userstr = localStorage.getItem("user");
    if(userstr){
      const user = JSON.parse(userstr);
      if(user){
        if(!user.endTime){
          user.endTime = Date.now();
          localStorage.setItem("user",JSON.stringify(user))
          next();
        }else{ 
          if(Date.now() - user.endTime > localStorage.getItem("cssj")){
            localStorage.removeItem("user");
            console.log("时间超时跳转到登陆页面")
            next('/login');
          }else{
            user.endTime = Date.now();
            localStorage.setItem("user",JSON.stringify(user))
            next();
          }
        }
        
      }
    }else{
      console.log("用户没登陆")
      next('/login');
    }
  }})