import axios from 'axios'
import { ElLoading } from 'element-plus'
import 'element-plus/dist/index.css'
import store from '@/store/index'
import router from '../router'
 
//配置全局数据请求类型
axios.defaults.headers['Content-Type'] = "application/json";
 
//自定义实例
const service = axios.create({
	baseURL: "",				//数据请求地址
	timeout: 30 * 1000
})
 
//配置加载参数
let loadingOption = {
	text: "正在努力加载中...",
	background: "rgba(0, 0, 0, 0.7)"
}, loading;
 
//请求拦截器
service.interceptors.request.use(config => {
	//开始加载
	loading = ElLoading.service(loadingOption);
	//数据转换
	config.data = 'object'===typeof config.data ? JSON.stringify(config.data) : config.data;
	//追加访问令牌
	config.headers['authtoken'] = localStorage.getItem("authtoken")
	console.log(config)
	return config;
}, error => {
	loading.close();
	return Promise.reject(error);
});
 
//响应拦截器
service.interceptors.response.use(response => {
	loading.close();
	if(response.status == 200){ 
		return response['data'];
	}
	if(response.status == 401){
		localStorage.removeItem("user");
		localStorage.removeItem("authtoken");
		console.log("系统未授权，跳转到登陆")
		router.push("login")
	}
	return Promise.reject(response);
}, error => {
	loading.close();
	return Promise.reject(error);
});
 
export default service;