<template>
  <div>
    <el-row  type="flex">
      <el-col :span="1" :offset="2">
        <el-tooltip
            class="box-item"
            effect="dark"
            content="新增用户"
            placement="top-start"
          >
          <el-button type="primary" size="small" @click="add">
            <el-icon ><Plus /></el-icon>
          </el-button>
       </el-tooltip>
        
      </el-col>
      <el-col :span="4"> 
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="">
            <el-input v-model="queryusername" placeholder="搜索输入姓名"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" :icon="Search" @click="queryuser" >查询</el-button>
      </el-col>
    </el-row>
    <el-row  type="flex" class="row-bg">
      <el-col :span="19" :offset="2">
        <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%">
        <el-table-column
          fixed
          prop="uname"
          label="姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="orgname"
          label="部门"
          width="120">
        </el-table-column>
        <el-table-column v-if="false"
          prop="rid"
          label="工号"
          width="120">
        </el-table-column>
        <el-table-column
          prop="phonenum"
          label="手机号"
          width="150">
        </el-table-column>
        <el-table-column
          prop="wxuname"
          label="微信名"
          width="150">
        </el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          width="290">
        </el-table-column>
        <el-table-column
          prop="birthday"
          label="生日"
          width="150">
        </el-table-column>
        <el-table-column 
          label="操作"
          width="160">
          <template #default="scope">
            <el-button type="primary" size="small" @click="edit(scope.row)">
              <Edit style="width: 1em; height: 1em;" /> 
            </el-button>
            <el-button type="primary" size="small" @click="deleteRow(scope.row.rid)">
              <Delete style="width: 1em; height: 1em;" /> 
            </el-button> 
          </template> 
        </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row  class="row-bg">
      <el-col :span="1" :offset="12">
        <el-pagination  background layout="sizes,prev, pager, next,total"  
          :total="totalItems" 
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="pagenum"
          v-model:page-size="pagesizeItems"
          >
        </el-pagination>
      </el-col> 
    </el-row>

    <el-dialog
      v-model="showEdit"
      :title="title"
      width="650"
      align-center
    >
   <!-- 内容区 -->
    <el-form :inline="true"  class="demo-form-inline">
      <el-form-item label="姓名">
        <el-input v-model="uname" placeholder="姓名" clearable />
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="pwd" placeholder="密码" type="password" show-password /> 
      </el-form-item>
      <el-form-item label="部门"> 
        <el-tree-select
          v-model="orgid" 
          :data="treeData"
          placeholder="请选择部门"
          :props="props"
        />
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker
          v-model="birthday"
          type="date"
          placeholder="生日"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        />
      </el-form-item>
      <el-form-item label="手机">
        <el-input v-model="phonenum" placeholder="手机号" clearable />
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="address" placeholder="地址" clearable />
      </el-form-item>
      <el-form-item label="微信用户名">
        <el-input v-model="wxuname" placeholder="微信用户名" clearable />
      </el-form-item>
    </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="showEdit = false">取消</el-button> 
          <el-button type="primary" @click="save">保存</el-button> 
        </div>
      </template>
    </el-dialog>
  </div>
  
</template>

<script> 
  import service from '@/util/request'
  import urlcfg from '@/config/urlcfg'
  import { ElMessage } from 'element-plus'

 

  export default {
    methods: {
      queryuser(){
        this.loadtable();
      },
      edit(row){ 
        this.rid = row.rid;
        this.pwd = row.pwd;
        this.orgid = row.orgid;
        this.uname = row.uname;
        this.birthday = row.birthday;
        this.phonenum = row.phonenum;
        this.address = row.address;
        this.wxuname=row.wxuname;
        this.showEdit = true; 
        this.title = '修改';
        this.isadd =  false;
      },
      deleteRow(rid) { 
        service.get(urlcfg.systemdeluser,{
          params:{
            rid:rid
          }}
        ).then(res => {
            if(res.statCode == '200'){
              ElMessage({
                message: res.data,
                type: 'success',
              })
              this.loadtable();
           }else{
              ElMessage.error(res.data)
           }

        }).catch(function (error) {
          console.log(error);
        });
      },
      loadtable(){
          service.post(urlcfg.systemuserlist,{
            queryusername:this.queryusername,
            pagesize:this.pagesizeItems,
            pagenum:this.pagenum
          }).then(response => {
          //给表格内容赋值
          this.tableData = response.data.data;
          //给翻页菜单赋值
          this.totalItems = response.data.total;

          console.log(this.totalItems)
        }).catch(function (error) {
          console.log(error);
        });
      },
      loadAllTree(){
        service.get(urlcfg.systemallorglist).then(response => {
          if(response.statCode == '200'){
            this.treeData = response.data;
          }
        }).catch(function (error) {
          console.log(error);
        });
      },
      //树形菜单获取数据
      load(node, resolve){ 
        service.get(urlcfg.systemorglist,{
          params:{
            orgid:node.data.value?node.data.value:'TOP'
          }
        }).then(response => {
          let res = [];
          for (let element of response.data) {
            // 遍历数组，对每个元素进行操作
            res.push({value: element.orgid,
              label: element.orgname,
              isLeaf: element.isLeaf > 0 ?false:true
            }) 
          }
          resolve(res);
        }).catch(function (error) {
          console.log(error);
        });
      },
      add(){
        this.showEdit = true;
        this.title = '新增';
        this.pwd = '';
        this.orgid = '';
        this.uname = '';
        this.birthday = '';
        this.phonenum = '';
        this.address = '';
        this.wxuname='';
        this.isadd =  true;
      },
      save(){
        service.post(this.isadd?urlcfg.systemsaveuser:urlcfg.systemedituser,{
            rid:this.rid,
            pwd:this.pwd,
            orgid:this.orgid,
            uname:this.uname,
            birthday:this.birthday,
            phonenum:this.phonenum,
            address:this.address,
            wxuname:this.wxuname
        }).then(res => {
           console.log(res)
           if(res.statCode == '200'){
              ElMessage({
                message: res.data,
                type: 'success',
              })
              this.showEdit = false;
              this.pwd = '';
              this.orgid = '';
              this.uname = '';
              this.birthday = '';
              this.phonenum = '';
              this.address = '';
              this.wxuname='';
           }else{
              ElMessage.error(res.data)
           }
           this.showEdit = false;
           this.loadtable();
        }).catch(function (error) {
          ElMessage.error(error)
          console.log(error);
        });
      },
      handleSizeChange(pagesize){
        console.log(pagesize)
         this.loadtable();
      },
      handleCurrentChange(index){
          console.log(index)
          this.loadtable();
      }
    },
    mounted(){ 
      this.loadtable();
      this.loadAllTree();
    },
    data() {
      return {
        tableData: [{ }],
        treeData:[{}],
        totalItems:0,
        pagesizeItems:20,
        showEdit:false,
        title:'新增',
        isadd:true,
        birthday:'',
        rid:'',
        uname:'', 
        pwd:'', 
        orgid:'', 
        orgname:'', 
        phonenum:'',
        address:'',
        wxuname:'',
        queryusername:'',
        pagenum:1,
        props:{
          label: 'label',
          children: 'children',
          isLeaf: 'isLeaf',
        }
      }
    }
  }
</script> 
      
<style scoped>
 .el-select {
  width: 200px;
 }
 .el-form-item__label{
  min-width: 80px;
  max-width: 100px;
 }

</style>   