import { createRouter, createWebHashHistory } from 'vue-router'
import main from '@/components/main.vue'
import user from '@/components/user.vue'
import role from '@/components/role.vue'
import menu from '@/components/menu.vue'
import login from '../views/login'
import layout from '../views/layout'

const routes = [
  { path: '/',component: login}, 
  { path: '/layout',component: layout,
    children:[
      { path: 'main', component: main },
      { path: 'user',component: user},
      { path: 'menu',component: menu},
      { path: 'org',component: ()=>import('@/components/org.vue')},
      { path: 'role',component: role},
      { path: 'cpgl',component: ()=>import('@/components/cpgl.vue')},
      { path: 'cpzx',component: ()=>import('@/components/cpzx.vue')},      
    ]
  },
  { path: '/login',name: 'login',component: login},
  { path: '/cpxq',name: 'cpxq',component: ()=>import('../views/cpxq')}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
