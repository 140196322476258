export default ({
    systeminit:"/api/config/getconfigBycslx", //获取系统初始化数据
    systemconfig:"/api/config/getoconfig", //获取系统初始化数据
    systemuserlist:"/api/system/getuser", //系统获取用户数据列表
    systemorglist:"/api/system/getorg",    //获取系统机构信息
    systemallorglist:"/api/system/getAllorg",    //获取所有系统机构信息
    systemSaveOrg:"/api/system/addorg",    //保存机构
    systemDelOrg:"/api/system/delorg",    //保存机构

    systemsaveuser:"/api/system/saveUser",  //保存用户信息
    systemedituser:"/api/system/editUser",  //修改用户信息
    systemdeluser:"/api/system/deluser",     //删除用户信息

    systemroleadd:"/api/system/roleadd",     //新增角色
    systemrolelist:"/api/system/rolelist",     //获取角色列表信息
    systemroleuserlist:"/api/system/roleuserlist",     //获取角色用户列表信息
    systemsaveroleuser:"/api/system/saveroleuser",     //保存用户角色关系
    systemdelrole:"/api/system/systemdelrole",          //删除角色
    systemrolemenulist:"/api/system/systemrolemenulist",          //角色菜单列表
    systemsaverolement:"/api/system/systemsaverolement",          //保存角色菜单关系

    systemsavemenu:"/api/system/systemsavemenu",     //保存菜单信息
    systemeditmenu:"/api/system/systemeditmenu",     //修改菜单信息
    systemmenulist:"/api/system/systemmenulist",     //保存菜单列表信息
    systemdelmenu:"/api/system/systemdelmenu",     //删除菜单列表
    systemlogin:"/api/login/login",          //用户登陆

    cpglgetCatalogueList:"/api/cpgl/getCatalogueList",   //产品目录列表
    cpgldelCatalogue:"/api/cpgl/cpgldelCatalogue",   //产品目录删除
    cpglsaveCatalogue:"/api/cpgl/saveCatalogue",    //产品目录列表
    cpglsaveProduct:"/api/cpgl/saveProduct",        //产品保存
    cpgldelProduct:"/api/cpgl/delProduct",        //产品保存
    cpglqueryProduct:"/api/cpgl/queryProduct",       //产品列表
    cpglqueryProductContent:"/api/cpgl/queryProductContent"       //产品明细
})