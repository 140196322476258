<template>
  <div>
      <el-carousel   arrow="always" height="600px" >  
        <el-carousel-item  :key="item.rid" v-for="item in gdt"  > 
          <div>
            <div style="position: absolute;z-index: 2;left: 20px;top: 125px;font-size: 60px;color: #fff;">{{ item.title }}</div> 
            <img :src="item.urlpath" width="100%"  height="100%"  alt=""  > 
          </div>
          
        </el-carousel-item>
      </el-carousel>
  </div>
  
</template>
    
<script setup>
  import { ref, onMounted } from 'vue'
  import service from '@/util/request'
  import urlcfg from '@/config/urlcfg'
  import { ElMessage } from 'element-plus'
 
  const gdt = ref([]);

  onMounted(()=>{

    service.post(urlcfg.cpglqueryProduct,{
      pagesize:5,
      pagenum:1,
      cataloguerid:"cpml",
      brief:"",
      title:""
    }).then(res => {
      if(res.statCode == '200'){
        gdt.value = res.data.data;
        console.log(gdt.value)
      }else{
          ElMessage.error(res.data)
      } 
    })
  });

</script>

<style scoped>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .el-carousel__container{
    height: 600px;
  }
</style>
    