<template>
  <div>
    <el-row  type="flex">
      <el-col :span="1" :offset="2">
        <el-tooltip
            class="box-item"
            effect="dark"
            content="新增菜单"
            placement="top-start"
          >
          <el-button type="primary" size="small" @click="add">
            <el-icon ><Plus /></el-icon>
          </el-button>
       </el-tooltip>
        
      </el-col>
      <el-col :span="4"> 
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="">
            <el-input v-model="querymenuname" placeholder="搜索输入菜单名"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" :icon="Search" @click="querymenu" >查询</el-button>
      </el-col>
    </el-row>
    <el-row  type="flex" class="row-bg">
      <el-col :span="19" :offset="2">
        <el-table
        :data="tableData"
        stripe
        :border="true"
        row-key="rid"
        :lazy="true"
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        style="width: 100%">
        <el-table-column
          fixed
          prop="menuname"
          label="菜单名"
          width="220">
        </el-table-column>
        <el-table-column
          prop="pmenuname"
          label="上级菜单"
          width="220">
        </el-table-column>
        <el-table-column  
          prop="menuurl"
          label="菜单地址"
          width="415">
        </el-table-column>
        <el-table-column  
          prop="ord"
          label="排序"
          width="60">
        </el-table-column>       
        <el-table-column 
          label="操作"
          width="160">
          <template #default="scope">
            <el-button type="primary" size="small" v-if="scope.row.pid == 'TOP'" @click="addchildren(scope.row)">
              <el-icon ><Plus /></el-icon>
            </el-button>
            <el-button type="primary" size="small" @click="edit(scope.row)">
              <Edit style="width: 1em; height: 1em;" /> 
            </el-button>
            <el-button type="primary" size="small" @click="deleteRow(scope.row)">
              <Delete style="width: 1em; height: 1em;" /> 
            </el-button> 
          </template> 
        </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row  class="row-bg">
      <el-col :span="1" :offset="12">
        <el-pagination  background layout="sizes,prev, pager, next,total"  
          :total="totalItems" 
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="pagenum"
          v-model:page-size="pagesizeItems"
          >
        </el-pagination>
      </el-col> 
    </el-row>

    <el-dialog
      v-model="showEdit"
      :title="title"
      width="650"
      align-center
    >
   <!-- 内容区 -->
    <el-form :inline="true"  class="demo-form-inline">
      <el-form-item label="菜单名">
        <el-input v-model="menuname" placeholder="菜单名"   />
      </el-form-item>
      <el-form-item label="上级菜单">
        <el-input v-model="pmenuname" placeholder="上级菜单"   /> 
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="ord" placeholder="1"   /> 
      </el-form-item>
      <el-form-item label="菜单地址"> 
        <el-input v-model="menuurl" placeholder="菜单地址"  /> 
      </el-form-item>
    </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="showEdit = false">取消</el-button> 
          <el-button type="primary" @click="save">保存</el-button> 
        </div>
      </template>
    </el-dialog>
  </div>
  
</template>

<script> 
  import service from '@/util/request'
  import urlcfg from '@/config/urlcfg'
  import { ElMessage } from 'element-plus'

  export default {
    methods: {
      querymenu(){
        this.loadtable();
      },
      edit(row){ 
        this.rid = row.rid;
        this.pid = row.pid;
        this.ord = row.ord;
        this.menuurl = row.menuurl;
        this.menuname = row.menuname;
        this.pmenuname = row.pmenuname;
        this.level = row.level;
        this.showEdit = true; 
        this.title = '修改';
        this.isadd =  false;

      },
      load(row,treeNode,resolve){  
        service.post(urlcfg.systemmenulist,{
            pid:row.rid,
            pagesize:100,
            pagenum:1
          }).then(response => {
            resolve(response.data.data);    
        }).catch(function (error) {
          console.log(error);
        });
          
      },
      addchildren(row){
        this.rid = '';
        this.pid = row.rid;
        this.menuurl = row.menuurl;
        this.menuname = '';
        this.pmenuname = row.menuname;
        this.level = row.level + 1;
        this.showEdit = true; 
        this.title = '添加子菜单';
        this.isadd =  true;
      },
      deleteRow(row) {  
        service.get(urlcfg.systemdelmenu,{
          params:{
            rid:row.rid
          }}
        ).then(res => {
            if(res.statCode == '200'){
              ElMessage({
                message: res.data,
                type: 'success',
              })
              window.location.reload();
           }else{
              ElMessage.error(res.data)
           }

        }).catch(function (error) {
          console.log(error);
        });
      },
      loadtable(){
          service.post(urlcfg.systemmenulist,{
            menuname:this.querymenuname,
            pagesize:this.pagesizeItems,
            pagenum:this.pagenum
          }).then(response => {
          //给表格内容赋值
          this.tableData = response.data.data;
          //给翻页菜单赋值
          this.totalItems = response.data.total;
 
        }).catch(function (error) {
          console.log(error);
        });
      }, 
      add(){
        this.showEdit = true;
        this.title = '新增';
        
        this.pid = 'TOP';
        this.pmenuname='顶级菜单';
        this.menuname = '';
        this.menuurl = '';
        this.level = 0;
        this.ord = 1;

        this.isadd =  true;
      },
      save(){
        service.post(this.isadd?urlcfg.systemsavemenu:urlcfg.systemeditmenu,{
            menuname:this.menuname,
            pmenuname:this.pmenuname,
            menuurl:this.menuurl,
            pid:this.pid,
            level:this.level,
            ord:this.ord,
            rid:this.rid
        }).then(res => { 
           if(res.statCode == '200'){
              ElMessage({
                message: res.data,
                type: 'success',
              })
              window.location.reload();
           }else{
              ElMessage.error(res.data)
           }
           this.showEdit = false;
           this.loadtable();
        }).catch(function (error) {
          ElMessage.error(error)
          console.log(error);
        });
      },
      handleSizeChange(pagesize){
        console.log(pagesize)
         this.loadtable();
      },
      handleCurrentChange(index){
          console.log(index)
          this.loadtable();
      }
    },
    mounted(){ 
      this.loadtable(); 
    },
    data() {
      return {
        tableData: [{ }],
        treeData:[{}],
        totalItems:0,
        pagesizeItems:20,
        showEdit:false,
        title:'新增',
        isadd:true,

        pid:'', 
        pmenuname:'',
        rid:'',
        menuname:'',
        menuurl:'', 
        level:'',
        ord:1,

        querymenuname:'',
        pagenum:1,
        props:{
          label: 'label',
          children: 'children',
          isLeaf: 'isLeaf',
        }
      }
    }
  }
</script> 
      
<style scoped>
 .el-select {
  width: 200px;
 }
 .el-form-item__label{
  min-width: 80px;
  max-width: 80px;
 } 

</style>   